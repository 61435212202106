<template>
  <div class="holder flex align-items-center">
    <!-- {{ storeAccount.notification }} -->
    <template v-if="storeAccount.notification != null">
      <div class="flex cursor-pointer mr-4" @click="onClicNotification">
        <i class="pi pi-envelope" style="font-size: 1.25rem"></i>
        <Badge class="envelope-badge" :value="cNotificationNum" severity="danger"></Badge>
      </div>
    </template>

    <!-- <div class="flex cursor-pointer mr-4" @click="onClickQuestion"  aria-haspopup="true" aria-controls="feedback-menu">
			<i class="pi pi-question-circle hover:text-700" style="font-size: 1.25rem"></i>
		</div>
		<Menu ref="rFeedbackMenu" id="feedback-menu" :model="items" :popup="true" /> -->

    <a @click="onClickDotMenu" ref="rDotMenuButton" class="cursor-pointer mr-3">
      <img :src="menuIcon" width="22" class="block" />
    </a>

    <div class="dot-menu-holder" v-if="rData.showDotMenu">
      <div class="inner-holder">
        <div class="font-header4 pl-3" style="padding-top: calc(1.5rem - 15px) !important; margin-bottom: -5px">MEYZER3602 Business Suite</div>
        <div class="grid">
          <div class="col-6 sm:col-4 flex justify-content-center" v-for="(item, i) in storeSite.mbsItems" :key="i">
            <div :class="['item-holder', item.disabled ? 'disabled' : '']" @click="item.func">
              <img class="icon" style="width: 3.5rem; height: 3.5rem" :src="item.img" />
              <div class="desc" style="margin-top: -6px">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="font-header4 mt-4 pl-3" style="margin-bottom: -5px">Products</div>
        <div class="grid">
          <div class="col-6 sm:col-4 flex justify-content-center" v-for="(item, i) in storeSite.vasItems" :key="i">
            <div :class="['item-holder', item.disabled ? 'disabled' : '']" @click="item.func">
              <img class="icon" style="width: 3.5rem; height: 3.5rem" :src="item.img" />
              <div class="desc" style="margin-top: -6px">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="pt-3"></div>
      </div>
    </div>

    <CustomAvatar :pName="storeAccount.user.firstName || storeAccount.user.fullName" @click="onClickProfileMenu" ref="rProfileMenu" pSize="mid" class="cursor-pointer" :pAvatarUrl="storeAccount.user.displayPicture" />
    <div class="profile-menu-holder" v-if="rData.showProfileMenu">
      <div class="inner-holder pt-3 pb-4">
        <template v-for="(item, index) in storeSite.profileMenuItems" :key="index">
          <template v-if="item.link">
            <router-link :to="item.link" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
              <div @click="navigate" class="profile-menu-item flex align-items-center gap-3 p-2 pl-4">
                <i :class="['menu-icon', item.icon]" />
                <span :class="['label']">{{ item.label }}</span>
              </div>
            </router-link>
          </template>
          <template v-else-if="item.func">
            <div @click="item.func" class="profile-menu-item flex align-items-center gap-3 p-2 pl-4">
              <i :class="['menu-icon', item.icon]" />
              <span :class="['label']">{{ item.label }}</span>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeMount, computed, watch, setBlockTracking, reactive } from "vue";
import Button from "primevue/button";
import CustomAvatar from "@/components/CustomAvatar.vue";
import menuIcon from "@/assets/icons/icon-menu.svg";
import router from "@/router";
import env from "@/helpers/Environment";
import Cookies from "js-cookie";
import { notificationType } from "@/helpers/Constant";
import OverlayPanel from "primevue/overlaypanel";

import ShareFunc from "@/helpers/ShareFunc";

import { onClickOutside } from "@vueuse/core";
import { useStoreAccount } from "@/stores/store.account";
import { useStoreSite } from "@/stores/store.site";

const rFeedbackMenu = ref(null);
const rDotMenuButton = ref(null);
const rProfileMenu = ref(null);
const rData = reactive({
  dotMenuButton: null,
  showDotMenu: false,
  showProfileMenu: false,
});

const storeAccount = useStoreAccount();
const storeSite = useStoreSite();
const cNotificationNum = computed(() => {
  const n1 = storeAccount.notification[notificationType.COMPANY_ACCOUNT_INVITATION];
  const n2 = storeAccount.notification[notificationType.EXT_PURCHASE_MODULE_INVITATON];
  return n1.length + n2.length;
});

const items = ref([
  {
    label: "Contact Us",
    icon: "pi pi-envelope",
    command: () => {
      window.open("/contact", "_blank");
      //router.push("/contact");
    },
  },
]);

const onClickDotMenu = () => {
  rData.showDotMenu = !rData?.showDotMenu;
};
onClickOutside(rDotMenuButton, () => (rData.showDotMenu = false));

const onClickProfileMenu = () => {
  rData.showProfileMenu = true;
};
onClickOutside(rProfileMenu, () => (rData.showProfileMenu = false));

const onClicNotification = () => {
  router.push("/notification");
};

const onClickQuestion = (event) => {
  rFeedbackMenu.value.toggle(event);
};
</script>

<style scoped lang="scss">
.envelope-badge {
  position: absolute;
  margin-top: -8px;
  margin-left: 12px;

  min-width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.75rem;
}

.dot-menu-holder {
  background: #fcfcfd;
  border-radius: 0.5rem;
  position: absolute;
  z-index: 999;
  right: 16px;
  top: 54px;
  padding: 8px;
  width: 328px; // width: 380px;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);

  .inner-holder {
    width: 100%;
    background: #ffffff;
    border-radius: 13px;
    .grid {
      margin-top: 0;
      //  margin-left: 1em;
      //   margin-right: 1em;
      .item-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 8px;
        text-align: center;
        width: 90px;
        border-radius: 0.5rem;
        cursor: pointer;
        .icon {
          filter: grayscale(100%);
        }
        .desc {
          font-size: 0.8rem;
          max-width: 85px;
        }

        &:hover {
          background-color: var(--surface-100);
          .icon {
            filter: none;
          }
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.2;
        }
      }
    }
  }
}

.profile-menu-holder {
  background: #fcfcfd;
  border-radius: 0.5rem;
  position: absolute;
  z-index: 999;
  right: 16px;
  top: 54px;
  padding: 8px;
  width: 180px;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);

  .inner-holder {
    width: 100%;
    background: #ffffff;
    border-radius: 0.5rem;

    .profile-menu-item {
      color: #666e80;
      cursor: pointer;
      &:hover {
        color: #404040;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .dot-menu-holder {
    width: 280px;
  }
}
</style>
